.site-header {
  @include media-query($on-palm) {
    border-top-width: 2px;
    min-height: $spacing-unit * 1.4;
    line-height: $base-line-height * 12px * 2.25;
  }
}

.site-title {
  @include media-query($on-palm) {
    font-size: 12px;
  }
}

.wrapper {
  @include media-query($on-palm) {
    max-width: calc(95% - (#{$spacing-unit}));
  }
}
